import {createState as createHookState, State as HookState} from '@hookstate/core'

import {QuoteApi} from './QuoteApi'
import {dateFromIso, Draft, dump, enumerate, newUUID, toIsoDate} from '@peachy/utility-kit-pure'
import {
    createBlankQuote,
    createDefaultPlan,
    isValid,
    Life,
    LifeType,
    LifeTypes,
    Plan,
    Quote,
    QuoteResponse,
    toBlueprint,
    toClass,
    validate
} from '@peachy/legacy-domain'
import {
    createNetwork,
    StageNetworkDefinition,
    StageState,
    StageStateResponse,
    StageTransitionNetwork
} from '@peachy/utility-kit-browser/src/stage-network/StageNetwork'

// exposed public api
export type QuoteService = {

    createLifeFrom(life: Draft<Life>)
    createLife(type: LifeType)

    editLife(id: string)
    addLife(life: Draft<Life>)
    updateLife(life: Draft<Life>)

    removeLife(id: string)

    createPlan()
    editPlan(id: string)
    addPlan(plan: Draft<Plan>)
    updatePlan(plan: Draft<Plan>)
    cancel()

    requestQuote()
    clearQuote()
    loadQuote(quote: Draft<Quote>, quoteStage?: QuoteStage)

    apply()
    reopen()
    buy()

    getCurrentQuote(): HookState<Draft<Quote>>
    getCurrentQuoteStage(): HookState<StageState<QuoteStageName, QuoteStageData>>
}

// the stages (or states) the service can be in at any point -  as in a state transition network,
// but using the term 'stage' given the overloaded meaning of state in react worlds!
export const QuoteStageNames = enumerate([
    'BUILD_QUOTE',
    'CREATE_LIFE',
    'EDIT_LIFE',
    'CREATE_PLAN',
    'EDIT_PLAN',
    'PENDING',
    'APPLY',
    'BOUGHT',
    'ERROR'
] as const)

// the stages as type union
export type QuoteStageName = keyof typeof QuoteStageNames

// additional data we can store at each stage
export type QuoteStageData = {
    error?: any,
    draftLife?: Draft<Life>
    draftPlan?: Draft<Plan>
}

export type QuoteStage = StageState<QuoteStageName, QuoteStageData>

export type QuoteSession = {
    quote: Draft<Quote>
    quoteStage: QuoteStage
}

// factory function for service instance
export function createQuoteService(quoteApi: QuoteApi): QuoteService {

    // actual quote model state
    const quote = createHookState<Draft<Quote>>(createBlankQuote())

    // helper method to convert to class instance to gain method access
    function quoteToClass(): Quote {
        return toClass(quote.value, Quote)
    }

    // the internal stage transition network definition
    const quoteNetDef: StageNetworkDefinition<QuoteStageName, QuoteStageData> = {
        BUILD_QUOTE: {
            addLife,
            addPlan,
            createLife,
            createLifeFrom,
            createPlan,
            removeLife,
            editLife,
            editPlan,
            updateLife,
            updatePlan,
            requestQuote,
            clearQuote,
            loadQuote,
            apply: () => quoteNetDef.APPLY,
        },
        APPLY: {
            reopen: () => quoteNetDef.BUILD_QUOTE,
            buy: () => quoteNetDef.BOUGHT
        },
        BOUGHT: {
            noop: () => undefined
        },
        CREATE_LIFE: {
            addLife,
            cancel: () => quoteNetDef.BUILD_QUOTE,
        },
        EDIT_LIFE: {
            updateLife,
            cancel: () => quoteNetDef.BUILD_QUOTE,
        },
        CREATE_PLAN: {
            addPlan,
            cancel: () => quoteNetDef.BUILD_QUOTE,
        },
        EDIT_PLAN: {
            updatePlan,
            cancel: () => quoteNetDef.BUILD_QUOTE,
        },
        PENDING: {
            onQuoteSuccess(response: QuoteResponse) {
                quote.response.set(response)
                return quoteNetDef.BUILD_QUOTE
            },
            onQuoteFailure(error: any) {
                throw error
            },
        },
        ERROR: {
            retry() {
                return quoteNetDef.ERROR
            }
        }
    }

    // the actual runtime network interface {dispatch(...), getCurrentState()}
    const network: StageTransitionNetwork<QuoteStageName, QuoteStageData> = createNetwork(quoteNetDef, quoteNetDef.BUILD_QUOTE)

    function createLifeFrom(life: Draft<Life>, onDefaultPlan: boolean = true): StageStateResponse<QuoteStageData> {
        const {id: _, planId: __, ...propsToCopy} = life
        const draftLife: Draft<Life> = {...propsToCopy}

        if (onDefaultPlan) {
            draftLife.planId = quoteToClass().request.getDefaultPlan().id
        }
        return [quoteNetDef.CREATE_LIFE, {draftLife}]
    }

    function createLife(type: LifeType, onDefaultPlan: boolean = true): StageStateResponse<QuoteStageData> {
        const draftLife: Draft<Life> = {type}
        if (onDefaultPlan) {
            draftLife.planId = quoteToClass().request.getDefaultPlan().id
        }
        return [quoteNetDef.CREATE_LIFE, {draftLife}]
    }

    function createPlan(): StageStateResponse<QuoteStageData> {
        const draftPlan: Draft<Life> = createDefaultPlan()
        return [quoteNetDef.CREATE_LIFE, {draftPlan}]
    }

    // bunch of event handler logic extracted to de-clutter the definition above
    function addLife(life: Draft<Life>) {
        const lifeClass = toClass(life, Life)
        const quoteClass = quoteToClass()

        lifeClass.id = newUUID()

        // todo - pass this in!
        lifeClass.planId = quoteClass.request.getDefaultPlan().id

        const startDate = dateFromIso(quoteClass.request.startDate)

        if (isValid(lifeClass, startDate, Life)) {
            const lifeExists = quoteClass.request.hasLife(lifeClass.id)
            if (lifeExists) throw 'EXISTING_LIFE_ID_ON_ADD'

            if (lifeClass.is(LifeTypes.PRIMARY)) {
                updateNonPrimaryLivesWithPrimaryLifeAddress(lifeClass)
                quote.request.lives.set(lives => [toBlueprint(lifeClass), ...lives])
            } else {
                quote.request.lives.merge([toBlueprint(lifeClass)])
            }

            return requestQuote()
        } else {
            dump(validate(lifeClass, startDate, Life))

            return undefined
        }
    }

    function updateNonPrimaryLivesWithPrimaryLifeAddress(primaryLife: Life) {
        const nonPrimaryLives = quoteToClass().request.getNonPrimaryLives();
        if (nonPrimaryLives.length > 0) {
            nonPrimaryLives.forEach(npLife => {
                quote.request.lives.find(l => l.get().id == npLife.id)
                    .address.set(primaryLife.address)
            })
        }
    }

    function editLife(id: string): StageStateResponse<QuoteStageData> {
        const life = quoteToClass().request.findLife(id)
        return life ? [quoteNetDef.EDIT_LIFE, {draftLife: toBlueprint(life)}] : undefined
    }

    function removeLife(id: string) {
        const lifeIndex = quoteToClass().request.findLifeIndex(id)
        if (lifeIndex < 0) throw 'UNKNOWN_LIFE_ID_ON_UPDATE'

        quote.request.lives.set(lives => lives.filter(life => life.id !== id))
        return requestQuote()
    }

    function updateLife(life: Draft<Life>) {
        const lifeClass = toClass(life, Life)
        const quoteClass = quoteToClass()
        const startDate = dateFromIso(quoteClass.request.startDate)

        if (isValid(lifeClass, startDate)) {
            const xLifeIndex = quoteToClass().request.findLifeIndex(lifeClass.id)
            if (xLifeIndex < 0) throw 'UNKNOWN_LIFE_ID_ON_UPDATE'
            quote.request.lives[xLifeIndex].set(toBlueprint(lifeClass))
            return requestQuote()
        } else {
            return undefined
        }
    }

    function addPlan(plan: Draft<Plan>) {
        const planClass = toClass(plan, Plan)
        planClass.id = newUUID()

        const quoteClass = quoteToClass()
        const startDate = dateFromIso(quoteClass.request.startDate)

        if (isValid(planClass, startDate)) {
            const planExists = quoteToClass().request.findPlan(planClass.id)
            if (planExists) throw 'EXISTING_PLAN_ID_ON_ADD'

            quote.request.plans.merge([toBlueprint(planClass)])
            return requestQuote()
        } else {
            return undefined
        }
    }

    function editPlan(id: string): StageStateResponse<QuoteStageData> {
        const plan = quoteToClass().request.findPlan(id)
        return plan ? [quoteNetDef.EDIT_PLAN, {draftPlan: toBlueprint(plan)}] : undefined
    }

    function updatePlan(plan: Draft<Plan>) {
        const planClass = toClass(plan, Plan)
        const quoteClass = quoteToClass()
        const startDate = dateFromIso(quoteClass.request.startDate)

        if (isValid(planClass, startDate)) {
            const planIndex = quoteToClass().request.findPlanIndex(planClass.id)
            if (planIndex < 0) throw 'UNKNOWN_PLAN_ID_ON_UPDATE'

            quote.request.plans[planIndex].set(toBlueprint(planClass))
            return requestQuote()
        } else {
            return undefined
        }
    }

    function clearQuote() {
        quote.set(createBlankQuote())
        return quoteNetDef.BUILD_QUOTE
    }

    function loadQuote(newQuote: Draft<Quote>, quoteStage?: QuoteStage) {
        quote.set(newQuote)
        if (quoteStage) {
            network.currentStage().set(quoteStage)
        }
        return null
    }

    function requestQuote() {
        quote.request.timestamp.set(toIsoDate(new Date()))
        quote.request.id.set(newUUID())

        const quoteRequest = quoteToClass().request
        const startDate = dateFromIso(quoteRequest.startDate)

        if (isValid(quoteRequest, startDate)) {
            quoteApi.requestQuote(quoteRequest)
                .then(response => network.dispatch(quoteNetDef.PENDING.onQuoteSuccess, response))
                .catch(error => network.dispatch(quoteNetDef.PENDING.onQuoteFailure, error))

            return quoteNetDef.PENDING
        } else {
            return quoteNetDef.BUILD_QUOTE
        }
    }

    // return the service instance
    return {
        createLife(type: LifeType, onDefaultPlan = true) {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.createLife, type, onDefaultPlan)
        },

        createLifeFrom(life: Draft<Life>, onDefaultPlan = true) {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.createLifeFrom, life, onDefaultPlan)
        },

        editLife(id: string) {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.editLife, id)
        },

        removeLife(id: string) {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.removeLife, id)
        },

        addLife(life: Draft<Life>) {
            return (network.dispatch(quoteNetDef.CREATE_LIFE.addLife, life)
                || network.dispatch(quoteNetDef.EDIT_LIFE.addLife, life))
        },

        updateLife(life: Draft<Life>) {
            return (network.dispatch(quoteNetDef.BUILD_QUOTE.updateLife, life)
                || network.dispatch(quoteNetDef.EDIT_LIFE.updateLife, life))
        },

        createPlan() {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.createPlan)
        },

        editPlan(id: string) {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.editPlan, id)
        },

        addPlan(plan: Draft<Plan>) {
            return (network.dispatch(quoteNetDef.BUILD_QUOTE.addPlan, plan)
                || network.dispatch(quoteNetDef.CREATE_PLAN.addPlan, plan))
        },

        updatePlan(plan: Draft<Plan>) {
            return (network.dispatch(quoteNetDef.BUILD_QUOTE.updatePlan, plan)
                || network.dispatch(quoteNetDef.EDIT_PLAN.updatePlan, plan))
        },

        cancel() {
            network.dispatch(quoteNetDef.CREATE_LIFE.cancel)
            network.dispatch(quoteNetDef.CREATE_PLAN.cancel)
            network.dispatch(quoteNetDef.EDIT_LIFE.cancel)
            network.dispatch(quoteNetDef.EDIT_PLAN.cancel)
        },

        requestQuote() {
            network.dispatch(quoteNetDef.BUILD_QUOTE.requestQuote)
        },

        clearQuote() {
            network.dispatch(quoteNetDef.BUILD_QUOTE.clearQuote)
        },

        loadQuote(quote: Quote, quoteStage?: QuoteStage) {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.loadQuote, quote, quoteStage)
        },

        apply() {
            return network.dispatch(quoteNetDef.BUILD_QUOTE.apply)
        },

        reopen() {
            return network.dispatch(quoteNetDef.APPLY.reopen)
        },

        buy() {
            return network.dispatch(quoteNetDef.APPLY.buy)
        },

        // isValidLife(life: Blueprint<Life>): life is Valid<Life> {
        //     return isValid(life, Life)
        // },
        //
        // isValidPlan(plan: Blueprint<Plan>): plan is Valid<Plan> {
        //     return isValid(plan, Plan)
        // },
        //
        // validateLife(life: Blueprint<Life>) {
        //     return validate(life, Life)
        // },
        //
        // validatePlan(plan: Blueprint<Plan>) {
        //     return validate(plan, Plan)
        // },

        getCurrentQuote() {
            return quote
        },
        getCurrentQuoteStage() {
            return network.currentStage()
        }
    }
}
