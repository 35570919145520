import {Promo as StripePromo} from '@peachy/payments-pure'
import {useEffect} from 'react'
import {API} from '@aws-amplify/api'
import {useSearchParams} from 'react-router-dom'

import {Promo, Promo as QuoteSessionPromo, Quote, toClass} from '@peachy/legacy-domain'
import {useCookies} from '@peachy/utility-kit-browser'
import {CouponService} from '@peachy/payments-client'
import {useCurrentQuote} from '../Quote/QuoteController'
import {ROOT_DOMAIN} from '../App/App'


const couponService = new CouponService(API)
const PROMO_PARAM_NAME = 'coupon'

export function useDetectPromo() {

    const [queryParams] = useSearchParams()
    const cookies = useCookies()
    const quote = useCurrentQuote()

    useEffect(() => {
        const uriPromo = queryParams.get(PROMO_PARAM_NAME)
        const cookiePromo = cookies.get(PROMO_PARAM_NAME)
        const promoCode = uriPromo ?? cookiePromo

        console.log('resolve promo:', {uriPromo, cookiePromo, promoCode})

        if (promoCode) {

            if (!!uriPromo && uriPromo !== cookiePromo) {
                const domain = getRootDomain()
                console.log('saving promo to cookie', {coupon: promoCode, domain})
                cookies.set(PROMO_PARAM_NAME, promoCode, {ttlDays: 1, domain})
            }

            couponService.getPromo(promoCode).then(promo => {
                const coupon = promo.coupon
                if (promo?.active && promo.coupon.valid) {
                    quote.request.promo.set({
                        code: promoCode,
                        blurb: promo.metadata?.blurb,
                        captureCardBlurb: promo.metadata?.capture_card_blurb,
                        fixedDiscountInPence: ifGtZero(coupon.amount_off),
                        percentDiscount: ifGtZero(coupon.percent_off),
                        emailRestrictions: getEmailRestrictionsFrom(promo),
                        reflectOnQuotedPrice: !(promo.metadata?.hide_from_quoted_price === 'true'),
                        terms: promo.metadata?.terms
                    })
                }
            })
        }
    }, [queryParams])

}

export function useGetPromoIfValidForPrimaryLife(): Promo | undefined {
    const quote = useCurrentQuote()
    const quoteClass = toClass(quote.value, Quote)
    const primaryLife = quoteClass.request.getPrimaryLife()
    const promo = quote.request.promo.get() as Promo
    const valid = isValidForRedeemer(promo, primaryLife.email)
    return valid ? promo : undefined
}

function ifGtZero(val: number) {
    return val && val > 0 ? val : undefined
}

function getEmailRestrictionsFrom(promo: StripePromo) {
    return promo.metadata?.email_restrictions?.trim()?.split(' ')?.map(it =>
        it?.trim()?.toLowerCase()
    ).filter(it =>
        it?.length > 0
    ) ?? []
}

function isValidForRedeemer(promo: QuoteSessionPromo | undefined, redeemerEmail: string) {

    if (!promo) {
        return false
    }

    if (promo.emailRestrictions.length == 0) {
        return true
    }

    const lowerCaseRedeemerEmail = redeemerEmail.toLowerCase()
    return promo.emailRestrictions.some(restriction => {
        const domainWildcard = restriction.startsWith('*') && restriction.substring(1)
        const acceptedDomain = domainWildcard && lowerCaseRedeemerEmail.endsWith(domainWildcard)
        return acceptedDomain || lowerCaseRedeemerEmail === restriction
    })

}


function getRootDomain() {
    const prodRootDomain = ROOT_DOMAIN
    const host = location.hostname
    // worst case assume prod so it always works there
    if (!host || host.includes(prodRootDomain)) {
        return prodRootDomain
    } else { //otherwise do some hacky magic to work out the root domain whatever env
        return host.substring(Math.max(host.indexOf('.peachy'), 0))
    }
}
