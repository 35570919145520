import React from 'react'

import {rawTableDataFromCache} from './datepicker-util'
import {
    areSameDateArray,
    classList,
    DateArray,
    dateArrayFrom,
    dateFrom,
    isWithinBounds,
    NOOP,
    thisMonth,
    thisYear
} from '@peachy/utility-kit-pure'

export default function CalendarTable(props) {

    const {
        selectedDate,
        displayMonthYear = [thisMonth(), thisYear()],
        upperBound,
        lowerBound,
        onSelect = NOOP,
        selectedDateClassName,
        todayClassName,
        weekendClassName,
        offMonthClassName,
        outOfBoundsClassName,

    } = props


    const [displayMonth, displayYear] = displayMonthYear

    const today = dateArrayFrom(new Date())

    const rawTableData = rawTableDataFromCache(displayMonthYear)

    return (
        <table>
            <thead>
            <tr>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
            </tr>
            </thead>
            <tbody>
            {rawTableData.map((week, weekKey) =>
                <tr key={weekKey}>
                    {week.map((day, dayKey) => {

                        const classes = []
                        if (displayMonth !== day[1]) classes.push(offMonthClassName)
                        if (!isWithinBounds(dateFrom(day), lowerBound, upperBound)) classes.push(outOfBoundsClassName)
                        if (dayKey === 0 || dayKey === 6) classes.push(weekendClassName)
                        if (areSameDateArray(day, today)) classes.push(todayClassName)

                        if (selectedDate && areSameDateArray(day, dateArrayFrom(selectedDate))) {
                            classes.push(selectedDateClassName)
                        }


                        return (
                            <td className={classList(...classes)}
                                key={dayKey} onClick={() => isWithinBounds(dateFrom(day), lowerBound, upperBound) && onSelect(dateFrom(day))}>
                                {day[0]}
                            </td>
                        )

                    })}
                </tr>
            )}
            </tbody>
        </table>
    )
}

export type CalendarTableProps = {
    onSelect: () => void,
    selectedDate: DateArray,
    displayMonthYear: string[],
    selectedDateClassName: string,
    todayClassName: string,
    weekendClassName: string,
    offMonthClassName: string,
    upperBound?: Date,
    lowerBound?: Date,
}
