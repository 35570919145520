import React, {ReactNode, useContext, useRef, useState} from 'react'
import {PaymentsService} from '@peachy/payments-client'
import {Elements} from '@stripe/react-stripe-js'
import useAsyncEffect from 'use-async-effect'
import {PaymentApiGatewayDefinition, PaymentIntent} from '@peachy/payments-pure'
import {API} from '@aws-amplify/api'


import {makeApiGatewayClient} from '@peachy/core-domain-client'

import {createPaymentsService} from '@peachy/payments-client/src/PaymentsService'
import Spinner from '../elements/Spinner/Spinner'

const PaymentsContext = React.createContext<PaymentsService | null>(null)


type PaymentsControllerProps = {
    children: ReactNode
}


export default function PaymentsController({children}: PaymentsControllerProps) {

    const paymentServiceRef = useRef<PaymentsService>()
    const paymentService = paymentServiceRef.current

    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>()

    useAsyncEffect(async (isActive) => {

        const paymentService = await createPaymentsService(await makeApiGatewayClient(PaymentApiGatewayDefinition, API))
        paymentServiceRef.current = paymentService

        await paymentService.createPaymentIntent()

        if (isActive()) {
            setPaymentIntent(paymentService.getIntent())
        }

    }, [])

    return (
        paymentIntent ? (
            <PaymentsContext.Provider value={paymentService}>
                <Elements stripe={paymentService.getStripe()} options={{
                    clientSecret: paymentService.getClientSecret(),
                    fonts: [{
                        cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'
                    }]
                }}>

                    {children}
                </Elements>
            </PaymentsContext.Provider>
        ) : <Spinner/>
    )
}

export function usePaymentsService(): PaymentsService {
    return useContext(PaymentsContext)
}
