import {Auth} from '@aws-amplify/auth'
import {fetchServiceConfig} from '@peachy/core-domain-client/src/fetchServiceConfig'
import {configureApiGateway} from '@peachy/core-domain-client'
import {useServicePatch} from '@peachy/client-kit'

let ROOT_DOMAIN: string

// const apis = [
//     'PaymentsApi',
//     'LookupApi',
//     'MarketplaceApi',
//     'SubscriptionApi',
//     'QuoteApi',
//     'Intercom'
// ]



export async function configureAws() {

    const servicePatchUrl = useServicePatch()

    const fetchServicePatch = (service: string) => fetchServiceConfig(service, servicePatchUrl)

    const domainConfig = await fetchServicePatch('DomainConfig')
    ROOT_DOMAIN = domainConfig.rootDomainName

    const iamConfig = await fetchServicePatch('IamConfig')

    Auth.configure(iamConfig)


    await configureApiGateway('PaymentsApi')
    await configureApiGateway('LookupApi')
    await configureApiGateway('MarketplaceApi')
    await configureApiGateway('QuoteApi')
    await configureApiGateway('Intercom')
}
