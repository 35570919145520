import React from 'react'
import {dateFromIso, Draft, Fields} from '@peachy/utility-kit-pure'
import useToggles from '../../hooks/useToggles'
import {CaptureFieldsProps} from '../DraftLife/CaptureForm'
import CaptureLifeOne from './CaptureLifeOne/CaptureLifeOne'
import {RouteStageProps} from '../StageRouter/StageRouter'
import {useCurrentQuote, useDraftLife, useQuoteService} from '../QuoteController'
import {Life, LifeTypes, toClass, validate} from '@peachy/legacy-domain'
import {QuoteStageName} from '@peachy/quote-client'
import useIntercom from '../../hooks/useIntercom'

export type CaptureLifeProps = RouteStageProps<QuoteStageName>

const lifeOneFields: Fields<Life>[] = ['firstname', 'lastname', 'birthDate', 'address', 'email']
const otherLifeFields: Fields<Life>[] = ['firstname', 'lastname', 'gender', 'birthDate', 'email']

export default function CaptureLife({}: CaptureLifeProps) {

    const quoteService = useQuoteService()
    const quote = useCurrentQuote()
    const startDate = dateFromIso(quote.request.startDate.value)
    const intercom = useIntercom()

    const draftLife = useDraftLife()

    const visitedFields = useToggles<Fields<Life>>(isPrimary(draftLife.value) ? lifeOneFields : otherLifeFields)
    const validationErrors = validate(toClass(draftLife.value, Life), startDate)

    const captureProps: CaptureFieldsProps<Life> = {
        instance: draftLife,
        visitedFields,
        validationErrors,
        onSubmit() {
            const primary = isPrimary(draftLife.value)
            const {email, firstname: name} = draftLife.value
            quoteService.addLife(draftLife.value)
            if (primary) {
                intercom.convertVisitorToLead({email, name}).then(response => {
                    console.log('CaptureLife convertVisitorToLead', response)
                })
            }
        }
    }

    return isPrimary(draftLife.value)
        ? <CaptureLifeOne {...captureProps}/>
        : <p/>


}

function isPrimary(life: Draft<Life>) {
    return life.type === LifeTypes.PRIMARY
}
