import {
    Address as LegacyAddress,
    CardDetails as LegacyPaymentDetails,
    Life as LegacyLife,
    Plan as LegacyPlan,
    PlanBenefit as LegacyBenefit,
    Quote as LegacyQuote
} from '@peachy/legacy-domain'
import {
    Address,
    MrAccount,
    MrLife,
    MrPlan,
    MrPlanBenefit,
    MrPolicy,
    MrSubscription,
    MrSubscriptionQuoteRequest,
    PaymentDetails
} from '@peachy/core-domain-pure'
import {dateFromIso, KeyMapped, mapById, newUUID, UK_TIMEZONE} from '@peachy/utility-kit-pure'
import {toCoreBenefitType} from '@peachy/quote-pure'

export async function mapFromLegacyPolicy(quote: LegacyQuote, legacyPaymentDetails: LegacyPaymentDetails, intercomId: string) {

    const account: MrAccount = {
        id: newUUID(),
        type: 'INDIVIDUAL',
        name: quote.request.getPrimaryLife().fullName(),
        contactEmail: quote.request.getPrimaryLife().email,
        contactName: quote.request.getPrimaryLife().fullName(),
        subscriptions: {},
    }

    const startDate = dateFromIso(quote.request.startDate)

    const subscription: MrSubscription = {
        id: newUUID(),
        name: quote.request.getPrimaryLife().fullName(),
        contactEmail: quote.request.getPrimaryLife().email,
        contactName: quote.request.getPrimaryLife().fullName(),
        plans: {},
        policies: {},
        paymentDetails: mapPaymentDetails(legacyPaymentDetails),
        startDate: startDate.getTime(),
        status: 'ACTIVE',
        timezone: UK_TIMEZONE,
        version: 0,
    }

    const subscriptionRequest: MrSubscriptionQuoteRequest = {
        account,
        subscription,
        plans: quote.request.plans.map(mapPlan),
        policies: [mapToPolicy(quote, startDate.getTime())],
        quoteRequestId: quote.request.id,
        quoteRequestTimestamp: Date.now(),
        intercomVisitorId: intercomId,

    }

    return subscriptionRequest
}




function mapToPolicy(legacyQuote: LegacyQuote, startDate: number): MrPolicy {
    return {
        id: legacyQuote.request.id,
        status: 'ACTIVE',
        startDate,
        version: '0',
        lives: mapLives(legacyQuote.request.lives, startDate)
    }
}


function mapLives(legacyLives: LegacyLife[], startDate: number): KeyMapped<MrLife> {
    return mapById(legacyLives.map((ll) => {
        const birthDate = dateFromIso(ll.birthDate)
        const life: MrLife = {
            id: ll.id,
            gender: ll.gender,
            email: ll.email,
            firstname: ll.firstname,
            lastname: ll.lastname,
            dateOfBirth: birthDate.getTime(),
            postcode: ll.address?.postcode,
            startDate,
            status: 'ACTIVE',
            type: ll.type,
            address: mapAddress(ll.address),
            benefits: {},
            planId: ll.planId
        }
        return life
    }))
}

function mapAddress(legacyAddress: LegacyAddress): Address {
    if (!legacyAddress) return null
    return {
        building: legacyAddress.building,
        country: legacyAddress.country,
        county: legacyAddress.county,
        display: legacyAddress.display,
        location: legacyAddress.location,
        postcode: legacyAddress.postcode,
        region: legacyAddress.region,
        settlement: legacyAddress.settlement
    }
}



function mapPlan(legacyPlan: LegacyPlan): MrPlan {
    return {
        id: legacyPlan.id,
        name: 'Individual Plan',
        benefits: mapBenefits(legacyPlan.benefits),
    }
}

function mapBenefits(legacyBenefits: LegacyBenefit[]) {
    return mapById(
        legacyBenefits.flatMap(lb => toCoreBenefitType(lb.name).map(cb => {
            const planBenefit: MrPlanBenefit = {
                id: cb,
                limit: lb.limit
            }
            return planBenefit
        }))
    )
}


function mapPaymentDetails(legacyPaymentDetails: LegacyPaymentDetails): PaymentDetails {
    const paymentDetails: PaymentDetails = {
        name: legacyPaymentDetails.name,
        email: legacyPaymentDetails.gatewayCustomer.email,
        stripePaymentId: legacyPaymentDetails.gatewayPaymentId,
        hasSeparateBillingAddress: legacyPaymentDetails.hasSeparateBillingAddress,
        billingAddress: mapAddress(legacyPaymentDetails.billingAddress),
        promoCode: legacyPaymentDetails.promoCode
    }
    return paymentDetails
}
