import {PolicyApi} from './PolicyApi'
import {CardDetails, isValid, PolicyRequest, PolicyResponse, Quote, toClass} from '@peachy/legacy-domain'
import {dateFromIso, Draft, toIsoDate} from '@peachy/utility-kit-pure'

export type PolicyService = {
    buy(quote: Draft<Quote>, cardDetails: Draft<CardDetails>, intercomVisitorId: string): Promise<PolicyResponse>
}


export function createPolicyService(policyApi: PolicyApi): PolicyService {
    return {
        async buy(quote: Draft<Quote>, cardDetails: Draft<CardDetails>, intercomVisitorId: string) {
            const quoteClass = toClass(quote, Quote)

            const startDate = dateFromIso(quote.request.startDate)

            if (isValid(quoteClass, startDate, Quote)) {
                const policyRequest = new PolicyRequest()
                policyRequest.quote = quoteClass
                policyRequest.id = quote.request.id
                policyRequest.timestamp = toIsoDate(new Date())
                policyRequest.cardDetails = toClass(cardDetails, CardDetails)
                policyRequest.intercomVisitorId = intercomVisitorId
                return policyApi.createPolicy(policyRequest)

            } else {
                throw 'Quote is invalid!'
            }
        }
   }
}
